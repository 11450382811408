const {animationConfig} = require('/js/animations/config.animation')

const someFunc = () => {
    [...(document.querySelectorAll('.cards > .row > a, .nav-item > a, .social-link > a, #themeBtn '))].forEach(img => {

        img.onmouseenter = () => {
            const targets = img.querySelectorAll('.path')

            anime(animationConfig.onHoverSVG(targets).opts)
        }

        img.onmouseleave = () => {
            const targets = img.querySelectorAll('.path')
            anime(animationConfig.offHoverSVG(targets).opts)
        }
    })
}

someFunc();